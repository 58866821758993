import React, { useState } from 'react';
import "./Contact.css"
import CommonBanner from '../../components/CommonBanner/CommonBanner';
import CommonBtn from '../../components/CommonBtn/CommonBtn';
import CommonHeading from '../../components/CommonHeading/CommonHeading';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useApi } from "../../ApiContext";

import box1 from "../../assets/images/home/form-top.png";

const Contact = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { postData } = useApi();
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await postData('/contact-us', data);
      setResponseMessage(response.message);
      reset()
      setSubmitted(true);
    } catch (error) {
      console.error('Error posting data:', error);
    }
    setLoading(false);
  };

  return (
    <>

      <Helmet>
        <title>Contact Us For Reservations & Inquiries | Desilicious Firangi </title>
        <meta name="description" content="Contact Desilicious Firangi for reservations, inquiries, or more information. Reach out via phone, email, or our contact form for all your dining needs." />

        <link rel="canonical" href="https://desiliciousfirangi.com/contact" />
      </Helmet>

      <CommonBanner Heading={"Contact"} Page={"Contact"} />


      {/* Contact Section */}
      <section className="contact-section">
        <div className="container c-p">
          <div className="contact-section-heading">
            <CommonHeading title={"Get In Touch"} />
          </div>
          <div className="contact-section-container">
            <div className="reservation-section-content">
              <div className="contact-page-form">
                <div className="contact-page-form-container">
                  <div className="form-top-box">
                    <img src={box1} alt="box" />
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} className="vertical-form contact-f">
                    <div className="form-container form-container2">

                      <div className="form-group contact-form-group">
                        <label>Name</label>
                        <input type="text" className='form-control' placeholder='Enter Your name' {...register('fullname', { required: 'Required' })} />
                        {errors.fullname && <label className="error-message">{errors.fullname.message}</label>}
                      </div>

                      <div className="form-group contact-form-group">
                        <label>Email</label>
                        <input type="email" className='form-control' placeholder='Enter Your Email' {...register('email', { required: 'Required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email' } })} />
                        {errors.email && <label className="error-message">{errors.email.message}</label>}
                      </div>

                      <div className="form-group contact-form-group">
                        <label>Subject</label>
                        <input type="text" className='form-control' placeholder='Subject' {...register('subject', { required: 'Required' })} />
                        {errors.subject && <label className="error-message">{errors.subject.message}</label>}
                      </div>

                      <div className="form-group contact-form-group">
                        <label>Message</label>
                        <textarea placeholder='Your message' className='form-control textarea-form-control' {...register('message', { required: 'Required' })} />
                        {errors.message && <label className="error-message">{errors.message.message}</label>}
                      </div>

                    </div>
                    <div className="contact-page-form-footer">
                      <CommonBtn text={"Submit"} type={"submit"} />
                    </div>
                  </form>
                  {submitted && <p className="success-message">Form submitted successfully!</p>}
                </div>
              </div>
              <div className="contact-page-location">
                <div className="location-details">
                  <div className="l-t">
                    <h6>Address:</h6>
                    <p><Link to="https://maps.app.goo.gl/trqgDegwGrd5LcpT6">7-9, Patrakar, Colony, Dholai, Mansarovar Extension, Jaipur</Link></p>
                  </div>

                  <div className="l-t">
                    <h6>Phone:</h6>
                    <p><Link to="tel:+91 9829012860"> +91 9829012860</Link></p>
                  </div>

                  <div className="l-t">
                    <h6>Email:</h6>
                    <p><Link to="mailto:desiliciousfirangi@gmail.com">desiliciousfirangi@gmail.com</Link></p>
                  </div>
                </div>
                <div className="location-map">
                  <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.958712870772!2d75.74138717588511!3d26.841265463156567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5fb7f05446d%3A0xc68c606a1ad49c6!2sDesilicious%20Firangi%20Multi%20cuisine%20Restaurant!5e0!3m2!1sen!2sin!4v1709384563485!5m2!1sen!2sin" width="100%" height="320" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact